import React, { useEffect, useState } from "react";
import SuperAdminDashboard from "../components/SuperAdminDashboard";
import TeamDashboard from "../components/TeamDashboard";

const CommonDashboard = () => {
  const role = localStorage.getItem("role");

  return (
    <div className={"piq-body"}>
      {role === "admin" ? <TeamDashboard /> : <SuperAdminDashboard />}
    </div>
  );
};

export default CommonDashboard;
