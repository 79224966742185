import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Login from "./pages/Login";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ProtectedRoute from "./components/ProtectionRoutes";
import CustomerCampaigns from "./pages/CustomerCampaignsPage";
import {jwtDecode} from "jwt-decode";
import CommonDashboard from "./pages/CommonDashboard";
import AdminProtectedRoute from "./components/AdminProtectedRoute";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgetPassword";

const getRole = () => {
    const token = localStorage.getItem("token");
    if (!token) {
        return null; // or handle the absence of token appropriately
    }
    const decodedToken = jwtDecode(token);
    const role = decodedToken.role;
    localStorage.setItem("role", role);
    return role;
};

export default getRole;


const router = createBrowserRouter([
    {
        path: "/",
        element: <Login/>,
    },
    {
        path: "/dashboard",
        element: <CommonDashboard/>,
    },
    {
        path: "/campaigns/:userId",
        element:
            <AdminProtectedRoute component={<CustomerCampaigns/>}/>,
    },
    {
        path: "/forgot-password",
        element: <ForgotPassword/>,
    },
    {
        path: "/reset-password/:token",
        element: <ResetPassword/>,
    },


]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
