import { FaBars, FaUser } from "react-icons/fa";
import logoWhite from "../assets/img/prospect-logo-white.webp";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

const AdminNavbar = () => {
  const navigate = useNavigate();

  const logoutHandler = () => {
    localStorage.clear();
    navigate("/");
  };
  return (
    <div className="dashboard-nav">
      <header className="dashboard-toolbar">
        <a href="#!" className="menu-toggle">
          <i>
            <FaBars />
          </i>
        </a>
      </header>
      <div className="d-flex justify-content-center mt-3">
        <a href="#" className="brand-logo ">
          <img src={logoWhite} style={{ width: "180px" }} />
        </a>
      </div>
      <nav className="dashboard-nav-list mt-3">
        <Link to="/dashboard" className="dashboard-nav-item">
          <i className="text-primary">
            <FaUser />
          </i>
          Customers
        </Link>
      </nav>
      <nav className="dashboard-nav-list sign-out-nav mb-3">
        <button
          href="#"
          onClick={logoutHandler}
          className="dashboard-nav-item bg-transparent text-white border-0"
        >
          <i className="fa fa-sign-out text-primary" aria-hidden="true"></i>
          Sign Out
        </button>
      </nav>
    </div>
  );
};
export default AdminNavbar;
