import AdminNavbar from "./AdminNavbar";
import { FaBars } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import CampaignsTable from "./CampaignsTable";
import CurrentTime from "./CurrentTime";
import axios from "axios";
import { Url } from "../functions/Url";

const CustomerCampaigns = () => {
  const { userId } = useParams();
  const [campaigns, setCampaigns] = useState([]);
  const [user, setUser] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [formState, setFormState] = useState({
    customer: "",
    campaignName: "",
    campaignId: "",
    company: "",
    outcome: "",
    userEmail: "",
  });
  const navigate = useNavigate();

  const fetchCampaigns = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${Url}team/campaigns/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCampaigns(response.data);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear();
        navigate("/");
      }
      console.error("Error fetching campaigns:", error);
      alert("Failed to fetch campaigns");
    }
  };

  const userInfo = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${Url}user/userInfo/${userId}`);
      setUser(response.data);
      setFormState((prevState) => ({
        ...prevState,
        customer: `${response.data.firstName} ${response.data.lastName}`,
        company: response.data.companyName,
        userEmail: response.data.email,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCampaigns();
    userInfo();
  }, []);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    document.getElementById("upload_csv_file_label").textContent =
      event.target.files[0].name;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCampaignChange = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const selectedOption = e.target.options[selectedIndex];
    const campaignId = selectedOption.value;
    const campaignName = selectedOption.text;

    setFormState((prevState) => ({
      ...prevState,
      campaignName: campaignName,
      campaignId: campaignId,
    }));
  };

  const handleUploadCSV = async () => {
    if (!selectedFile) {
      alert("Please select a CSV file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("campaignName", formState.campaignName);
    formData.append("campaignId", formState.campaignId);
    formData.append("userId", userId);
    formData.append("userEmail", formState.userEmail);
    formData.append("outcome", formState.outcome);

    try {
      const token = localStorage.getItem("token");
      await axios.post(`${Url}team/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      alert("CSV file uploaded successfully");
      fetchCampaigns();
      document.getElementById("upload_csv_file_label").textContent =
        "Select File";
      setSelectedFile(null);
    } catch (error) {
      console.error("Error uploading CSV file:", error);
      alert("Failed to upload CSV file");
    }
  };

  return (
    <>
      <div className="dashboard">
        <AdminNavbar />
        <div className="dashboard-app">
          <header className="dashboard-toolbar">
            <a href="#!" className="menu-toggle">
              <i>
                <FaBars />
              </i>
            </a>
          </header>
          <div className="p-4">
            <CurrentTime />
            <div
              className="card mt-5"
              style={{
                backgroundColor: "#e4edf4",
                borderRadius: "15px",
                border: "none",
              }}
            >
              <div className="card-body p-4">
                <div className="cl-filter-icon">
                  <div className="d-flex" id="campaign-box-header-titles">
                    <div className="me-auto text-dark-Campaigns">Campaigns</div>
                    <div className="me-3 text-dark-Campaigns title-right">
                      {formState.customer} - {formState.company}
                    </div>
                  </div>
                  <hr className="cl-heading-border" />
                </div>
                <div className="form-floating mt-3">
                  <div className="p-2 cl-internal-area-container">
                    <div className="scroll-area ps-4 pe-4">
                      <CampaignsTable
                        campaigns={campaigns}
                        setCampaigns={fetchCampaigns}
                      />

                      {/*// <!-- upload document -->*/}
                      <section id="drag-and-drop-campaign-section">
                        <div
                          className="container mt-3 mb-3"
                          id="drag-and-drop-campaign"
                        >
                          <div className="mx-auto p-4">
                            <div className="d-flex justify-content-center align-items-center">
                              <button
                                type="button"
                                className="campaignInfo-ok-btn"
                                data-bs-toggle="modal"
                                data-bs-target="#uploadCsvModal"
                              >
                                Upload CSV
                              </button>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*// <!-- uploadCsvModal -->*/}
      <div
        className="modal fade"
        id="uploadCsvModal"
        tabIndex="-1"
        aria-labelledby="uploadCsvModalModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: "450px" }}
        >
          <div className="modal-content uploadCsv-modalContent p-3 ps-4 pe-4">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label text-light">Customer</label>
                <select
                  className="form-select rounded"
                  name="customer"
                  aria-label="Default select example"
                  disabled
                  value={formState.customer}
                  onChange={handleInputChange}
                >
                  <option selected value="jane">
                    {user && user.firstName} {user && user.lastName}
                  </option>
                </select>
              </div>

              <div className="col-md-6 mb-3">
                <label className="form-label text-light">Campaign</label>
                <select
                  className="form-select rounded"
                  name="campaignName"
                  aria-label="Default select example"
                  value={formState.campaignId}
                  onChange={handleCampaignChange}
                >
                  <option selected>Select Campaign</option>
                  {campaigns.length > 0
                    ? campaigns.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.campaignName}
                        </option>
                      ))
                    : null}
                </select>
              </div>

              <div className="col-md-6 mb-3">
                <label className="form-label text-light">Company</label>
                <input
                  type="text"
                  name="company"
                  readOnly
                  value={formState.company}
                  className="form-control rounded"
                />
              </div>

              <div className="col-md-6 mb-3">
                <label className="form-label text-light">Outcome</label>
                <select
                  className="form-select rounded"
                  name="outcome"
                  aria-label="Default select example"
                  value={formState.outcome}
                  onChange={handleInputChange}
                >
                  <option selected>Select Outcome</option>
                  <option value="iq1">IQ1</option>
                  <option value="nurture">Nurture</option>
                </select>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div
                className="mb-3 uploadCsv-file-label text-center"
                id="upload_csv_file_label"
              >
                Select File
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <button
                  type="button"
                  className="btn btn-dark rounded"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>

              <div>
                <label
                  className="form-control uploadCsv-input-rounded"
                  onClick={() =>
                    document
                      .getElementById("upload_csv_file_input_field")
                      .click()
                  }
                >
                  <span>Upload CSV</span>
                </label>
                <input
                  type="file"
                  id="upload_csv_file_input_field"
                  name="select_file"
                  style={{ display: "none" }}
                  accept=".csv"
                  onChange={handleFileChange}
                />
              </div>

              <div>
                <button
                  type="button"
                  className="btn btn-dark rounded"
                  onClick={handleUploadCSV}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CustomerCampaigns;
