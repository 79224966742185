import CustomersTable from "./CustomersTable";
import AdminNavbar from "./AdminNavbar";
import { useEffect, useState } from "react";
import CurrentTime from "./CurrentTime";
import { FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Url } from "../functions/Url";
import axios from "axios";

const TeamDashboard = () => {
  const token = localStorage.getItem("token");
  const [customers, setCustomers] = useState([]);
  const navigate = useNavigate();

  const fetchCustomers = async () => {
    try {
      const response = await axios.get(`${Url}team/customers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
      setCustomers(response.data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // AxiosError: Handle specific Axios-related errors
        if (error.response) {
          // Server responded with a status code outside of 2xx range
          if (error.response.status === 401) {
            // Unauthorized, clear local storage and navigate to login
            localStorage.clear();
            navigate("/");
          } else {
            // Handle other server errors (e.g., 404, 500)
            console.error("Server Error:", error.response.data);
          }
        } else if (error.request) {
          // Request was made but no response received
          console.error("Network Error:", error.request);
        } else {
          // Something else happened while setting up the request
          console.error("Error:", error.message);
        }
      } else {
        // Handle non-Axios errors
        console.error("Error:", error.message);
      }
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  return (
    <div className="dashboard">
      <AdminNavbar />

      <div className="dashboard-app">
        <header className="dashboard-toolbar">
          <a href="#!" className="menu-toggle">
            <i>
              <FaBars />
            </i>
          </a>
        </header>
        <div className="p-4">
          <CurrentTime />
          <div
            className="card mt-5"
            style={{
              backgroundColor: "#e4edf4",
              borderRadius: "15px",
              border: "none",
            }}
          >
            <div className="card-body p-4">
              <div className="cl-filter-icon">
                <label>Customers</label>
                <hr className="cl-heading-border" />
              </div>
              <div className="form-floating mt-3">
                <div className="p-2 cl-internal-area-container">
                  <div className="scroll-area ps-4 pe-4">
                    <CustomersTable
                      customers={customers}
                      setCustomers={fetchCustomers}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TeamDashboard;
