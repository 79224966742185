import React from 'react';
import {useEffect, useState} from "react";
import {Link, useNavigate} from 'react-router-dom';
import logoWhite from "../assets/img/prospect-logo-white.webp";
import modelLogo from "../assets/img/prospect-logo.png";
import {FaUser} from "react-icons/fa"
import {FaBars} from "react-icons/fa";
import {Url} from "../functions/Url";
import AdminUsersTable from "./AdminUsersTable";
import axios from "axios";
import CurrentTime from "./CurrentTime";

const SuperAdminDashboard = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
    });
    const [adminUsers, setAdminUsers] = useState([]);
    const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");

    const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState("");

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();

      const dateOptions = {
        weekday: "long",
        month: "long",
        day: "numeric",
      };
      const timeOptions = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };

      const formattedDate = now.toLocaleDateString("en-US", dateOptions);
      const formattedTime = now.toLocaleTimeString("en-US", timeOptions);

      setCurrentDate(formattedDate);
      setCurrentTime(formattedTime);
    };

    updateTime();
    const intervalId = setInterval(updateTime, 60000); // Update every minute

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${Url}admin/users`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      console.log(response);
      setAdminUsers(response.data);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear();
        navigate("/");
      }else{
        console.log(error);
      }
    }
  };


    useEffect(() => {
        fetchUsers();

    }, []);


  const validate = () => {
    let tempErrors = {};
    if (!formData.firstName) tempErrors.firstName = "First name is required.";
    if (!formData.lastName) tempErrors.lastName = "Last name is required.";
    if (!formData.email) tempErrors.email = "Email is required.";
    if (!formData.password) tempErrors.password = "Password is required.";
    if (formData.password.length < 8)
      tempErrors.password = "Password must be at least 8 characters.";
    if (formData.password !== formData.confirmPassword)
      tempErrors.confirmPassword = "Passwords do not match.";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
    const  handleAddAdmin = async (e) => {
        e.preventDefault();
        // console.log("Add Admin");
        if (!validate()) return;
        try {
            const response = await fetch(`${Url}admin/register`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: JSON.stringify(formData),
            });
            if (!response.ok) {
                if(response.status === 401){
                    localStorage.clear();
                    navigate("/");
                }
                const errorData = await response.json();
                throw new Error(errorData.message || "Add Admin failed.");
            }
            const data = await response.json();
            console.log("Admin added successfully", data);
            window.location.reload();
        } catch (error) {
            // if (error.response.status === 401) {
            //     localStorage.clear();
            //     navigate("/");
            // }else{
            //     alert("Failed to add admin");
            // }
            console.error("Error adding admin:", error);
            alert("Failed to add admin, try again");


            setApiError(error.message);
        }
    };
    return (
        <>

        <div class='dashboard'>
    <div class="dashboard-nav">
       {/*<header class='dashboard-toolbar'><a href="#!" class="menu-toggle"><i><FaBars/></i></a></header>*/}
            <div class='d-flex justify-content-center mt-3'>
              <a href="#" class="brand-logo " >
                    <img src={logoWhite} style={{width: "180px"}}/>
              </a>
            </div>
            <nav class="dashboard-nav-list mt-3">
                <a href="#" onClick={()=>{
                    navigate("/dashboard");
                }} class="dashboard-nav-item" ><i className={"text-primary"}><FaUser/></i>Admins</a>
            </nav>
            <nav class="dashboard-nav-list">
                <a href="javascript:void(0);" class="dashboard-nav-item" data-bs-toggle="modal" data-bs-target="#addNewAdminModal" onclick="closeMobileMenu()"><i class="fa fa-user-plus text-primary" aria-hidden="true"></i>Add Admin</a>
            </nav>
			<nav class="dashboard-nav-list sign-out-nav mb-3">
            <a href="#" onClick={()=>{
                localStorage.clear();
                navigate("/");
            }} class="dashboard-nav-item"><i class="fa fa-sign-out text-primary" aria-hidden="true"></i> Sign Out</a>
        </nav>
    </div>

    <div class='dashboard-app'>
        <header class='dashboard-toolbar'><a href="#!" class="menu-toggle"><i><FaBars/></i></a></header>
            <div class='p-4'>
                <CurrentTime/>
                <div class="card mt-5" style={{backgroundColor: "#e4edf4", borderRadius: "15px", border: "none"}}>
                        <div class="card-body p-4">
                            <div class="cl-filter-icon">
                                <label>Admins</label>
                                <hr class="cl-heading-border"/>
                            </div>
                            <div class="form-floating mt-3" >
                                <div class="p-2 cl-internal-area-container">
                                <div class="scroll-area ps-4 pe-4">
                                    <AdminUsersTable data={adminUsers} setData={fetchUsers}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</div>

        <div class="modal fade" id="addNewAdminModal" tabindex="-1" aria-labelledby="addNewAdminModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content campaignInfo-modalContent" style={{border: "0px!important"}}>
                    <div class="card shadow border card-rounded">
                        <div class="d-grid gap-2 d-flex justify-content-end">
                            <button type="button" class="btn-close contactInfo-btn-rounded-circle" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="card-body p-4">
                            <div class="col-md-4 px-0">
                                <img src={modelLogo} class="img-fluid"/>
                            </div>

                            <h3 class="form-label mt-5" style={{fontSize: "20px"}}>Add Admin</h3>
                            <div class="form-text">Fill in your details below to add admin</div>
                            <form class="mt-3" onSubmit={handleAddAdmin}>
                                <div class="row">
                                    <div class="col-md-6 mb-3">
                                        <label class="form-label">First Name*</label>
                                        <input onChange={handleChange} type="text" name="firstName" value={formData.firstName} class="form-control input-rounded" required/>
                                        {errors.firstName && (
                                            <div class="text-danger">{errors.firstName}</div>
                                        )}
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label class="form-label">Last Name*</label>
                                        <input onChange={handleChange} value={formData.lastName} type="text" name="lastName" class="form-control input-rounded" required/>
                                        {errors.lastName && (
                                            <div class="text-danger">{errors.lastName}</div>
                                        )}
                                    </div>

                                    <div class="col-md-12 mb-3">
                                        <label class="form-label">Email*</label>
                                        <input type="email" name="email" value={formData.email} onChange={handleChange} class="form-control input-rounded" required/>
                                        {errors.email && (
                                            <div class="text-danger">{errors.email}</div>
                                        )}
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label class="form-label">Password*</label>
                                        <input onChange={handleChange} type="password" value={formData.password} name="password" class="form-control input-rounded" required/>
                                        {errors.password && (
                                            <div class="text-danger">{errors.password}</div>
                                        )}
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label class="form-label">Confirm Password*</label>
                                        <input onChange={handleChange} value={formData.confirmPassword} type="password" name="confirmPassword" class="form-control input-rounded" required/>
                                        {errors.confirmPassword && (
                                            <div class="text-danger">{errors.confirmPassword}</div>
                                        )}
                                    </div>
                                </div>
                                {apiError && <div className="text-danger">{apiError}</div>}
                                <div class="d-grid gap-2 col-12 mb-3 mx-auto">
                                    <button class="btn btn-primary button-rounded" type="submit">Create Account</button>
                                </div>
                                <div class="form-text text-dark">By clicking "Create account" you agree to ourTeam of Service and Privacy Policy</div>
                                <div class="form-text mb-2 mt-5">Having trouble? Contact us at <a href="#">support@prospectiqai</a></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </>

    );
}
export default SuperAdminDashboard;