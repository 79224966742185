import React from "react";
const CampaignsTable = ({ campaigns }) => {
  return (
    <React.Fragment>
      <table className="table">
        <thead>
          <tr
            className="text-secondary text-center"
            style={{ fontSize: "13px" }}
          >
            <th scope="col">Campaign Name</th>
            <th scope="col">Upload Data</th>
            <th scope="col"># of Leads</th>
            <th scope="col">Status</th>
            <th scope="col">IQ1</th>
            <th scope="col">Nurture</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {console.log(campaigns)}
          {campaigns &&
            campaigns.map((campaign, index) => (
              <tr key={index} className="text-center">
                <th scope="row">{campaign.campaignName}</th>
                <td>{campaign.uploadedDate}</td>
                <td>{campaign.numOfRows}</td>
                <td>
                  {campaign.status === "Processing" ? (
                    <span
                      className={`rounded-pill button-bg-warning status-pill p-1 ps-4 pe-4`}
                    >
                      {campaign.status}
                    </span>
                  ) : campaign.status === "Process Leads" ? (
                    <span
                      className={`rounded-pill button-bg-warning-danger status-pill p-1 ps-4 pe-4`}
                    >
                      {campaign.status}
                    </span>
                  ) : (
                    <span
                      className={`rounded-pill button-bg-info status-pill p-1 ps-4 pe-4`}
                    >
                      {campaign.status}
                    </span>
                  )}
                </td>
                {campaign.iq1Count.length > 0 ? (
                  <td>{campaign.iq1Count}</td>
                ) : (
                  <td>...</td>
                )}
                {campaign.nurtureCount.length > 0 ? (
                  <td>{campaign.nurtureCount}</td>
                ) : (
                  <td>...</td>
                )}
              </tr>
            ))}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default CampaignsTable;
