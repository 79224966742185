import React, {useEffect} from "react";
import CustomerCampaigns from "./CustomerCampaigns";
import {Link, useNavigate} from "react-router-dom";
const CustomersTable = ({ customers, setCustomers }) => {
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <table className="table">
                <thead>
                <tr className="table-hr text-center">
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Company Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Campaigns</th>
                </tr>
                </thead>
                <tbody>
                {customers && customers.map((user, index) => (
                    <tr key={index} className="text-center">
                        <td>{user.firstName}</td>
                        <td>{user.lastName}</td>
                        <td>{user.email}</td>
                        <td>{user.companyName}</td>
                        <td>{user.phone}</td>
                        <td>
                            {user.campaignCount === 0 ? (
                                user.campaignCount
                            ) : (
                                <Link to={`/campaigns/${user._id}`}>{user.campaignCount}</Link>
                                // <a href="#" onClick={()=>{
                                //     navigate(`/campaigns/${user._id}`);
                                // }}>{user.campaignCount}</a>
                            )}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

        </React.Fragment>
    )

}
export default CustomersTable;