import React from "react";
import {FaRegTrashCan} from "react-icons/fa6";
import axios from "axios";

import {Url} from "../functions/Url";
const AdminUsersTable = ({data, setData}) => {
    const deleteAdminUserRow = async (campaignId) => {
        try {
            const token = localStorage.getItem("token");

            const response = await axios.post(
                `${Url}admin/delete`,
                {
                    id: campaignId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                setData();
            }
        } catch (error) {
            if (error.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
            console.error("Error deleting admin user:", error);
            alert("Failed to delete admin user");
        }
    }
    return (
        <React.Fragment>
            <table className="table">
                <thead>
                <tr className="table-hr text-center">
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Action</th>
                </tr>
                </thead>
                <tbody>
                {data && data.map((user, index) => (
                    <tr key={index} className="text-center">
                        <td>{user.firstName}</td>
                        <td>{user.lastName}</td>
                        <td>{user.email}</td>
                        <td>
                            <span className="p-1">
                      <button
                          type="button"
                          className="trash-btn"
                          onClick={() => deleteAdminUserRow(user._id)}
                      >
                        <FaRegTrashCan/>
                      </button>
                    </span>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

        </React.Fragment>
    )


}

export default AdminUsersTable;