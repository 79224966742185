import {Navigate} from "react-router-dom";

const AdminProtectedRoute = ({component}) => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    return token && role === "admin" ? (
        component
    ) : (
        <Navigate to="/" />
    );

}
export default AdminProtectedRoute;