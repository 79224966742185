import React, {useEffect, useState} from "react";

const CurrentTime = () => {
    const [currentDate, setCurrentDate] = useState("");
    const [currentTime, setCurrentTime] = useState("");
      useEffect(() => {
    const updateTime = () => {
      const now = new Date();

      const dateOptions = {
        weekday: "long",
        month: "long",
        day: "numeric",
      };
      const timeOptions = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };

      const formattedDate = now.toLocaleDateString("en-US", dateOptions);
      const formattedTime = now.toLocaleTimeString("en-US", timeOptions);

      setCurrentDate(formattedDate);
      setCurrentTime(formattedTime);
    };

    updateTime();
    const intervalId = setInterval(updateTime, 60000); // Update every minute

    // return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

      return (
          <React.Fragment>
          <div>
              <h3 className="form-label text-dark">
                  {currentDate} <span className="ms-3">{currentTime}</span>
              </h3>
          </div>
          </React.Fragment>
      );


}
export default CurrentTime;