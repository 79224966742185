// ResetPassword.js
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../assets/css/style.css";
import logo from "../assets/img/prospect-logo.png";
import { Url } from "../functions/Url";

function ResetPassword() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState("");
  const [message, setMessage] = useState("");

  const validate = () => {
    let tempErrors = {};
    if (!password) {
      tempErrors.password = "Password is required.";
    } else if (password.length < 8) {
      tempErrors.password = "Password must be at least 8 characters.";
    }
    if (password !== confirmPassword) {
      tempErrors.confirmPassword = "Passwords do not match.";
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    try {
      const response = await fetch(`${Url}admin/reset-password/${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ password }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to reset password.");
      }

      const data = await response.json();
      setMessage(data.message);
      navigate("/"); // Redirect to login after success
    } catch (error) {
      setApiError(error.message);
    }
  };

  return (
    <div className="piq-body">
      <div className="container pt-5 mb-5">
        <div className="mx-auto" style={{ maxWidth: "500px" }}>
          <div className="card shadow border card-rounded">
            <div className="card-body p-5">
              <div className="col-md-4 px-0">
                <img src={logo} className="img-fluid" alt="Prospect Logo" />
              </div>

              <h3 className="form-label mt-3">Reset Password</h3>
              <form className="mt-3" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label className="form-label">New Password</label>
                    <input
                      type="password"
                      name="password"
                      required
                      className="form-control input-rounded"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {errors.password && (
                      <div className="text-danger">{errors.password}</div>
                    )}
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="form-label">Confirm Password</label>
                    <input
                      type="password"
                      name="confirmPassword"
                      required
                      className="form-control input-rounded"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    {errors.confirmPassword && (
                      <div className="text-danger">
                        {errors.confirmPassword}
                      </div>
                    )}
                  </div>
                </div>
                {apiError && <div className="text-danger">{apiError}</div>}
                {message && <div className="text-success">{message}</div>}
                <div className="d-grid gap-2 col-12 mb-3 mx-auto">
                  <button
                    className="btn btn-primary button-rounded"
                    type="submit"
                  >
                    Reset Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
