import CustomerCampaigns from "../components/CustomerCampaigns";

const CustomerCampaignsPage = () => {
    return (
        <div className={"piq-body"}>
            <CustomerCampaigns />
        </div>
    );

}
export default CustomerCampaignsPage;